import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto",style:({'background-color' : _vm.post.background}),attrs:{"max-width":"800"}},[_c('div',{staticClass:"text-center pa-3"},[(_vm.post.image)?_c('div',{staticClass:"pt-2 black"},[_c('img',{style:({'max-height' : '350px', 'max-width' : '100%'}),attrs:{"src":this.$store.state.base_url + '/posts/images/' + _vm.post.id + '/' + _vm.post.image}})]):_vm._e()]),_c(VCardText,{staticClass:"pa-5 pre-wrap text-center",style:({'color' : _vm.post.font_color})},[_vm._v(" “"+_vm._s(_vm.post.message)+"” ")]),_c(VCardActions,[_c(VListItem,{staticClass:"grow"},[_c(VListItemAvatar,{attrs:{"color":"grey darken-3"}},[_c('span',{staticClass:"text-h5 white--text"},[_vm._v(_vm._s(_vm.post.name.substring(0, 1)))])]),_c(VListItemContent,[_c(VListItemTitle,{style:({'color' : _vm.post.font_color})},[_vm._v(_vm._s(_vm.post.name))]),_c(VListItemSubtitle,{style:({'color' : _vm.post.font_color})},[_vm._v(_vm._s(_vm.post.created_at))])],1),_c(VRow,{attrs:{"align":"center","justify":"end"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v(" mdi-qrcode-scan ")])],1)],1)],1),(_vm.post.approved == 0)?_c(VCardActions,[_c(VBtn,{staticClass:"darken-3",attrs:{"large":"","color":"primary","block":"","disabled":this.$store.state.loading},on:{"click":function($event){return _vm.approvePost()}}},[_vm._v("approve")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
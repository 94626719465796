<template>
  <div class="carousel-container mx-auto mt-10">
      <v-carousel 
      hide-delimiters
      cycle
      height="auto"
      interval="6000"
      v-model="slide_idx"
      @change="getSlideIndex()">
      <v-carousel-item
        v-for="(post,i) in posts"
        :key="i"
      >
        <v-card
            class="mx-auto"
            color="primary"
            dark
            max-width="800"
        >
            <v-img
                v-if="post.image"
                :src="$store.state.base_url + '/posts/images/' + post.id + '/' + post.image"
            ></v-img>
            <v-card-text class="pa-5 pre-wrap">
            "{{ post.message }}"
            </v-card-text>

            <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                    class="elevation-6"
                    alt=""
                    src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                  <v-list-item-title>{{ post.name }}</v-list-item-title>
                  <v-list-item-subtitle>Jan 01, 2023 01:00PM</v-list-item-subtitle>
              </v-list-item-content>

              <v-row align="center" justify="end">
                  <v-icon class="mr-1">
                      mdi-qrcode-scan
                  </v-icon>
              </v-row>
            </v-list-item>
            </v-card-actions>
        </v-card>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    name : 'SlideShowView',
    data () {
      return {
        posts: [],
        slide_idx : 0
      }
    },
    mounted() {
      axios({
        url : this.$store.state.api_url + '/posts',
        method : 'GET'
      }).then(res => {
        this.posts = res.data;
      })
    },
    methods : {
      getSlideIndex() {
        console.log(this.slide_idx);
      }
    }
  }
</script>
<style scoped>
.carousel-container {
  max-width: 800px;
}
</style>
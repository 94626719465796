<template>
    <v-card
        class="mx-auto"
        :style="{'background-color' : post.background}"
        max-width="800"
    >
        <div class="text-center pa-3">
            <div class="pt-2 black" v-if="post.image">
                <img
                    :style="{'max-height' : '350px', 'max-width' : '100%'}"
                    :src="this.$store.state.base_url + '/posts/images/' + post.id + '/' + post.image"
                />
            </div>
        </div>
        <v-card-text class="pa-5 pre-wrap text-center" :style="{'color' : post.font_color}">
            &ldquo;{{ post.message }}&rdquo;
        </v-card-text>

        <v-card-actions>
        <v-list-item class="grow">
            <v-list-item-avatar color="grey darken-3">
                <span class="text-h5 white--text">{{ post.name.substring(0, 1) }}</span>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title :style="{'color' : post.font_color}">{{ post.name }}</v-list-item-title>
                <v-list-item-subtitle :style="{'color' : post.font_color}">{{ post.created_at }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-row align="center" justify="end">
                <v-icon class="mr-1">
                    mdi-qrcode-scan
                </v-icon>
            </v-row>
        </v-list-item>
        </v-card-actions>
        <v-card-actions v-if="post.approved == 0">
            <v-btn large 
                color="primary" 
                class="darken-3" 
                block 
                :disabled="this.$store.state.loading"
                @click="approvePost()">approve</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import axios from 'axios'
    export default {
        name : 'TestimonialDetailsDialog',
        props : ['post'],
        methods: {
            approvePost() {
                this.$store.commit('setLoading', true);
                axios({
                    url : this.$store.state.api_url + '/admin/posts/' + this.post.id + '/approve',
                    method : 'PUT',
                    headers : {
                        Authorization : 'Bearer ' + this.$store.state.user.token
                    }
                }).then(res => {
                    this.$store.commit('setLoading', false);
                    this.$emit('refreshList');
                }).catch(err => {
                    this.$store.commit('setLoading', false);
                });
            }
        }
    }
</script>
<style scoped>
.pre-wrap {
    white-space: pre-wrap; 
}
</style>
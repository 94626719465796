import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey darken-4 full bg"},[_c('div',{staticClass:"back-border"}),_c('img',{staticClass:"fixed-image-top",attrs:{"src":require("@/assets/ntesdp.png"),"height":"45","alt":""}}),_c('div',{staticClass:"pa-10"},[_c('masonry-wall',{attrs:{"items":_vm.posts,"ssr-columns":1,"column-width":300,"gap":16},scopedSlots:_vm._u([{key:"default",fn:function({ item, index }){return [_c(VCard,{staticClass:"mx-auto",style:({'background-color' : item.background}),attrs:{"max-width":"800","to":{name : 'Details', params : {id: item.id, uid : item.uid}},"shaped":""}},[(item.image)?_c(VImg,{attrs:{"src":_vm.$store.state.base_url + '/posts/images/' + item.id + '/' + item.image,"max-height":"500","position":"center"}}):_vm._e(),_c(VCardText,{staticClass:"text-center pre-wrap",style:({'color' : item.font_color})},[_vm._v(" “"+_vm._s(item.message)+"” ")]),_c(VCardActions,[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemAvatar,{attrs:{"color":"grey darken-4"}},[_c('span',{staticClass:"text-h5 white--text"},[_vm._v(_vm._s(item.name.substring(0, 1)))])]),_c(VListItemContent,[_c(VListItemSubtitle,{style:({'color' : item.font_color})},[_vm._v(_vm._s(item.name))]),_c(VListItemSubtitle,{style:({'color' : item.font_color})},[_vm._v(_vm._s(item.created_at))])],1)],1)],1)],1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
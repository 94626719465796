import { render, staticRenderFns } from "./SlideShowView.vue?vue&type=template&id=18942456&scoped=true&"
import script from "./SlideShowView.vue?vue&type=script&lang=js&"
export * from "./SlideShowView.vue?vue&type=script&lang=js&"
import style0 from "./SlideShowView.vue?vue&type=style&index=0&id=18942456&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18942456",
  null
  
)

export default component.exports

<template>
    <div class="pa-5">
        <div class="mb-5">
            <h3>Pending Posts</h3>
        </div>
        <v-data-table
            :headers="headers"
            :items="testimonials"
            class="elevation-1"
            >
            <template v-slot:body = {items}>
                <tbody>
                    <tr
                        v-for="item in items"
                        :key="item.id"
                    >
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.company }}</td>
                        <td>{{ item.position }}</td>
                        <td>{{ item.created_at }}</td>
                        <td>
                            <v-btn x-small :color="item.visible == 1 ? 'primary' : 'error'">
                                {{ item.visible == 1 ? 'Yes' : 'No' }}
                            </v-btn>
                        </td>
                        <td>
                            <v-btn x-small  color="primary" @click="viewPostDetails(item)">
                                View Details
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>

        <v-dialog v-model="post_dialog" width="500">
            <testimonial-details-dialog :post="post" @refreshList="refresh(); post_dialog = false;"></testimonial-details-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios';
    import TestimonialDetailsDialog from './dialog/TestimonialDetailsDialog.vue';
    export default {
        name : 'ApprovedTestimonialsListView',
        components : {
            TestimonialDetailsDialog
        },
        mounted() {
            this.refresh();
        },
        data() {
            return {
                post_dialog : false,
                post : {},
                search : '',
                headers : [
                    {
                        text : 'Name',
                        value : 'name',
                        sortable : true
                    },
                    {
                        text : 'Email',
                        value : 'email',
                        sortable : true
                    },
                    {
                        text : 'Company',
                        value : 'company',
                        sortable : true
                    },
                    {
                        text : 'Position',
                        value : 'position',
                        sortable : true
                    },
                    {
                        text : 'Submitted',
                        value : 'submitted',
                        sortable : true
                    },
                    {
                        text : 'Visible',
                        value : 'visible',
                        sortable : true
                    },
                    {
                        text : 'Details',
                    }
                ],
                testimonials : [

                ]
            }
        },
        methods: {
            viewPostDetails(post) {
                console.log(post);
                this.post_dialog = true;
                this.post = post;
            },
            refresh() {
                axios({
                    url : this.$store.state.api_url + '/admin/posts',
                    method : 'GET',
                    params : {approved : 0},
                    headers : {
                        Authorization : 'Bearer ' + this.$store.state.user.token
                    }
                }).then(res => {
                    this.testimonials = res.data;
                });
            }
        }
    }
</script>
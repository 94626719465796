<template >
    <div class="full-page grey darken-4 bg">
        <div class="pa-5">
            <v-card
                class="mx-auto"
                :style="{'background-color' : post.background}"
                max-width="800"
            >
                <div class="text-center pa-3">
                    <div class="black pt-2" v-if="post.image">
                        <img
                        :src="$store.state.base_url + '/posts/images/' + post.id + '/' + post.image"
                        :style="{'max-height' : '350px', 'max-width' : '100%'}"
                    />
                    </div>
                </div>
                <v-card-text class="text-h5 font-weight-bold pre-wrap text-center" :style="{'color' : post.font_color}">
                	&ldquo;{{ post.message }}&rdquo;
                </v-card-text>

                <v-card-actions>
                    <v-list-item class="grow">
                        <v-list-item-avatar color="grey darken-3">
                            <span class="text-h5 white--text">{{ post.name.substring(0, 1) }}</span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title :style="{'color' : post.font_color}">{{ post.name }}</v-list-item-title>
                            <v-list-item-subtitle :style="{'color' : post.font_color}">{{ post.created_at }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-row align="center" justify="end">
                            <v-btn dark :href="post.qr_code" download="qrcode.png" target="_blank" fab small>
                                <v-icon>
                                    mdi-qrcode-scan
                                </v-icon>
                            </v-btn>
                        </v-row>
                    </v-list-item>
                </v-card-actions>
                <v-divider>
                    
                </v-divider>
                <v-card-actions>
                    <v-btn small :to="{name : 'Dashboard'}" text block :color="post.font_color">Go to main dashboard</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'DetailsView',
    data() {
        return {
            post : {}
        }
    },
    mounted() {
        axios({
            url : this.$store.state.api_url + '/posts/' + this.$route.params.id + '/' + this.$route.params.uid,
            method : 'GET'
        }).then(res => {
            this.post = res.data;
        })
    },
    methods : {
    }
}
</script>
<style>
.full-page {
    width: 100%;
    height : 100%;
}
.bg-dark {
    background: #a3a3a3;
}
.pre-wrap {
    white-space: pre-wrap; 
}
.bg {
    background-image: url('@/assets/background-1.png');
    background-position-y: bottom;
    background-position-x: right;
    background-size:auto;
    background-color: black;
    background-attachment: fixed;
}
</style>
<template>
    <v-card>
        <img :src="qr_code" width="100%" height="auto" alt="">
    </v-card>
</template>
<script>
    export default {
        name : 'QRCodeDialog',
        props : ['qr_code']
    }
</script>
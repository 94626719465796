<template >
    <div class="full-page bg">
        <div class="pa-5">
            <v-card max-width="600" class="mx-auto" primary>
                <v-img
                    cover
                    position="center"
                    src="@/assets/banner.jpg">
                </v-img>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>
                            <v-text-field label="Name" 
                                dense 
                                outlined 
                                hide-details
                                prepend-icon="mdi-card-account-details"
                                v-model="user.name">
                            </v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-text-field label="Email (optional)" 
                                dense 
                                outlined 
                                hide-details
                                prepend-icon="mdi-email"
                                v-model="user.email">
                            </v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-text-field label="Position (optional)" 
                                dense 
                                outlined 
                                hide-details
                                prepend-icon="mdi-account-tie"
                                v-model="user.position">
                            </v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-text-field label="Company (optional)" 
                                dense 
                                outlined 
                                hide-details
                                prepend-icon="mdi-domain"
                                v-model="user.company">
                            </v-text-field>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-textarea 
                                prepend-icon="mdi-message-text"
                                label="Message"
                                placeholder="POST YOUR MESSAGE, GREETING, AND YOUR HOPES FOR PHILIPPINE TECHNICAL VOCATIONAL EDUCATION AND TRAINING (TVET)" 
                                dense 
                                outlined 
                                hide-details
                                v-model="user.message">
                            </v-textarea>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-file-input
                                show-size
                                outlined
                                accept="image/png, image/jpeg, image/bmp"
                                placeholder="Pick an avatar"
                                prepend-icon="mdi-camera"
                                label="Image (optional)"
                                dense
                                hide-details
                                v-model="user.image"
                            ></v-file-input>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-text>
                    <v-btn block color="primary" @click="submit()" :disabled="$store.state.loading">
                        <v-icon>mdi-check</v-icon>
                        {{ $store.state.loading ? 'Processing' : 'Submit' }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </div>
        <v-dialog v-model="error_dialog" width="400">
            <v-card dark color="red" class="darken-2">
                <v-list color="red" class="darken-2" dense>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>ERRORS</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="er in errors" :key="er">
                        <v-list-item-content>{{ er }}</v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text small @click="error_dialog = false;">close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'TestimonialView',
    data() {
        return {
            user : {},
            error_dialog : false,
            errors : []
        }
    },
    methods : {
        submit() {
            this.$store.commit('setLoading', true);
            let formData = new FormData();
            formData.append('name', this.user.name ? this.user.name : '');
            formData.append('email', this.user.email ? this.user.email : '');
            formData.append('company', this.user.company ? this.user.company : '');
            formData.append('position', this.user.position ? this.user.position : '');
            formData.append('message', this.user.message ? this.user.message : '');
            formData.append('image', this.user.image);
            axios({
                url : this.$store.state.api_url + '/posts',
                method : 'POST',
                data : formData,
                headers : {
                    'Content-Type' : undefined
                }
            }).then(res => {
                this.$store.commit('setLoading', false);
                this.$router.push({
                    name : 'Details',
                    params : {
                        id : res.data.id,
                        uid : res.data.uid
                    }
                });
            }).catch(err => {
                this.errors = err.response.data.errors;
                this.error_dialog = true;
                this.$store.commit('setLoading', false);
            });
        }
    }
}
</script>
<style>
.full-page {
    width: 100%;
    height : 100%;
}
.bg {
    background-image: url('@/assets/background-1.png');
    background-position-y: bottom;
    background-position-x: right;
    background-size:cover;
    background-color: black;
    background-attachment: fixed;
}
</style>
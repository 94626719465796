import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-5"},[_vm._m(0),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.testimonials},scopedSlots:_vm._u([{key:"body",fn:function({items}){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.company))]),_c('td',[_vm._v(_vm._s(item.position))]),_c('td',[_vm._v(_vm._s(item.created_at))]),_c('td',[_c(VBtn,{attrs:{"x-small":"","color":item.visible == 1 ? 'primary' : 'error'}},[_vm._v(" "+_vm._s(item.visible == 1 ? 'Yes' : 'No')+" ")])],1),_c('td',[_c(VBtn,{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.viewPostDetails(item)}}},[_vm._v(" View Details ")])],1),_c('td',[_c(VBtn,{attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.viewQRCode(item.qr_code)}}},[_c(VIcon,[_vm._v("mdi-qrcode-scan")])],1)],1)])}),0)]}}])}),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.post_dialog),callback:function ($$v) {_vm.post_dialog=$$v},expression:"post_dialog"}},[_c('testimonial-details-dialog',{attrs:{"post":_vm.post}})],1),_c(VDialog,{attrs:{"width":"350"},model:{value:(_vm.qr_code_dialog),callback:function ($$v) {_vm.qr_code_dialog=$$v},expression:"qr_code_dialog"}},[_c('qr-code-dialog',{attrs:{"qr_code":_vm.qr_code}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5"},[_c('h3',[_vm._v("Approved Posts")])])
}]

export { render, staticRenderFns }
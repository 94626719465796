import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-container mx-auto mt-10"},[_c(VCarousel,{attrs:{"hide-delimiters":"","cycle":"","height":"auto","interval":"6000"},on:{"change":function($event){return _vm.getSlideIndex()}},model:{value:(_vm.slide_idx),callback:function ($$v) {_vm.slide_idx=$$v},expression:"slide_idx"}},_vm._l((_vm.posts),function(post,i){return _c(VCarouselItem,{key:i},[_c(VCard,{staticClass:"mx-auto",attrs:{"color":"primary","dark":"","max-width":"800"}},[(post.image)?_c(VImg,{attrs:{"src":_vm.$store.state.base_url + '/posts/images/' + post.id + '/' + post.image}}):_vm._e(),_c(VCardText,{staticClass:"pa-5 pre-wrap"},[_vm._v(" \""+_vm._s(post.message)+"\" ")]),_c(VCardActions,[_c(VListItem,{staticClass:"grow"},[_c(VListItemAvatar,{attrs:{"color":"grey darken-3"}},[_c(VImg,{staticClass:"elevation-6",attrs:{"alt":"","src":"https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(post.name))]),_c(VListItemSubtitle,[_vm._v("Jan 01, 2023 01:00PM")])],1),_c(VRow,{attrs:{"align":"center","justify":"end"}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v(" mdi-qrcode-scan ")])],1)],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
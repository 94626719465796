import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VNavigationDrawer,{attrs:{"app":"","src":require("@/assets/side-background.png")},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"pt-5 pb-5 text-center logo-bg"},[_c('img',{staticClass:"mt-3",attrs:{"src":require("@/assets/logo.png"),"height":"75","alt":""}}),_c('div',{staticClass:"logo-text mt-3"},[_c('h2',[_vm._v("NTESDP")]),_c('div',[_c('strong',{staticClass:"red--text"},[_vm._v("2023-2028")])])])]),_c(VDivider),_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItemGroup,[_c(VListItem,{attrs:{"to":{name : 'PendingTestimonials'}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-message-text-clock")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("For Approval")])],1)],1),_c(VListItem,{attrs:{"to":{name : 'ApprovedTestimonials'}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-message-text")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Approved")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.viewDashboard()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-view-dashboard-variant")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Live View")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.logout()}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-power")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],1)],1)],1),_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_vm._v("NTESDP")])],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
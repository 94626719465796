<template>
    <div>
        <v-card class="mx-auto mt-10" max-width="300">
            <img :src="post.qr_code" width="100%" alt="">
        </v-card>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        name : 'QRCodeView',
        data() {
            return {
                post : {}
            }
        },
        mounted() {
            axios({
                url : this.$store.state.api_url + '/posts/' + this.$route.params.id + '/' + this.$route.params.uid,
                method : 'GET' 
            }).then(res => {
                this.post = res.data;
            }).catch(err => {

            })
        }
    } 
</script>
<template>
    <div class="grey darken-4 full bg">
        <div class="back-border"></div>
        <img src="@/assets/ntesdp.png" height="45" alt="" class="fixed-image-top">
        <div class="pa-10">
            <masonry-wall :items="posts" :ssr-columns="1" :column-width="300" :gap="16">
                <template #default="{ item, index }">
                    <v-card
                        class="mx-auto"
                        :style="{'background-color' : item.background}"
                        max-width="800"
                        :to="{name : 'Details', params : {id: item.id, uid : item.uid}}"
                        shaped
                    >
                        <v-img
                            v-if="item.image"
                            :src="$store.state.base_url + '/posts/images/' + item.id + '/' + item.image"
                            max-height="500"
                            position="center"
                        ></v-img>
                        <v-card-text class="text-center pre-wrap" :style="{'color' : item.font_color}">
                            &ldquo;{{ item.message }}&rdquo;
                        </v-card-text>

                        <v-card-actions>
                            <v-list-item dense>
                                <v-list-item-avatar color="grey darken-4">
                                    <span class="text-h5 white--text">{{ item.name.substring(0, 1) }}</span>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle :style="{'color' : item.font_color}">{{ item.name }}</v-list-item-subtitle>
                                    <v-list-item-subtitle :style="{'color' : item.font_color}">{{ item.created_at }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-actions>
                    </v-card>
                </template>
            </masonry-wall>
        </div>
    </div>
</template>
<style scoped>
    .back-border {
        opacity: .6;
        width: 100%;
        height: 15px;
        background: #000;
        position:fixed;
        top: 0px;
        z-index:9;
    }
    .fixed-image-top {
        position: fixed;
        z-index: 10;
        right : 0;
        top: 15px;
    }
    .full {
        width: 100%;
        height : 100%;
    }
    .bg {
        background-image : url('@/assets/dashboard-bg.png');
        background-size:cover;
        background-repeat: no-repeat;
        background-color: #fff;
        background-position: center;
        background-attachment: fixed;
    }
    .pre-wrap {
        white-space: pre-wrap; 
    }
    .fixed-logo {
        width: 300px;
        height : 80px;
        position: fixed;
        left: 0px;
        top: 0px;
    }
</style>
<script>
import axios from 'axios'
import Pusher from '@/plugins/pusher.js'
export default {
    name : 'SlideShowView',
    data () {
        return {
            posts: [],
            timer : null
        }
    },
    mounted() {
        Pusher.logToConsole = true;

        var pusher = new Pusher('f69685e43c93fed4d15c', {
            cluster: 'ap1'
        });

        var channel = pusher.subscribe('my-channel');
        channel.bind('my-event', data => {
            var posts = this.posts;
            posts.unshift(data);
            this.posts = JSON.parse(JSON.stringify(posts));

            clearInterval(this.timer);

            this.timer = setInterval(()=>{
                this.refresh(1);
                console.log('refreshed');
            }, 300000);
        });

        this.refresh();

        this.timer = setInterval(()=>{
            this.refresh(1);
            console.log('refreshed');
        }, 300000);
    },
    destroyed() {
        clearInterval(this.timer)
    },
    methods : {
        refresh(randomized = 0) {
            axios({
                url : this.$store.state.api_url + '/posts',
                method : 'GET',
                params : {randomized : randomized}
            }).then(res => {
                this.posts = res.data;
            });
        }
    }
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TestimonialView from '../views/user/TestimonialView.vue'
import ApprovedTestimonialsListView from '../views/admin/ApprovedTestimonialsListView.vue'
import PendingTestimonialsListView from '../views/admin/PendingTestimonialsListView.vue'
import AdminDashboardView from '../views/admin/AdminDashboardView.vue'
import SlideShowView from '@/views/public/SlideShowView.vue'
import DetailsView from '@/views/public/DetailsView.vue'
import LoginView from '@/views/LoginView.vue'
import QRCodeDetails from '@/views/public/QRCodeView.vue'
import DashboardView from '@/views/public/DashboardView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Testimonial',
    component: TestimonialView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    beforeEnter: (to, from, next) =>{
      if(localStorage.getItem('user')) {
        next({name : 'ApprovedTestimonials'})
      }
      else {
        next()
      }
    },
  },
  {
    path : '/admin',
    component : AdminDashboardView,
    beforeEnter: (to, from, next) =>{
      if(localStorage.getItem('user')) {
        next()
      }
      else {
        next({name : 'Login'})
      }
    },
    children : [
      {
        path: 'approved-testimonials',
        name: 'ApprovedTestimonials',
        component: ApprovedTestimonialsListView
      },
      {
        path: 'pending-testimonials',
        name: 'PendingTestimonials',
        component: PendingTestimonialsListView
      }
    ]
  },
  {
    path : '/slide-show',
    name : 'Slideshow',
    component : SlideShowView
  },
  {
    path : '/testimonials/:id/:uid',
    name : 'Details',
    component : DetailsView
  },
  {
    path : '/testimonials/code/:id/:uid',
    name : 'QRCodeDetails',
    component : QRCodeDetails
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router

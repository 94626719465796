import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-page grey darken-4 bg"},[_c('div',{staticClass:"pa-5"},[_c(VCard,{staticClass:"mx-auto",style:({'background-color' : _vm.post.background}),attrs:{"max-width":"800"}},[_c('div',{staticClass:"text-center pa-3"},[(_vm.post.image)?_c('div',{staticClass:"black pt-2"},[_c('img',{style:({'max-height' : '350px', 'max-width' : '100%'}),attrs:{"src":_vm.$store.state.base_url + '/posts/images/' + _vm.post.id + '/' + _vm.post.image}})]):_vm._e()]),_c(VCardText,{staticClass:"text-h5 font-weight-bold pre-wrap text-center",style:({'color' : _vm.post.font_color})},[_vm._v(" “"+_vm._s(_vm.post.message)+"” ")]),_c(VCardActions,[_c(VListItem,{staticClass:"grow"},[_c(VListItemAvatar,{attrs:{"color":"grey darken-3"}},[_c('span',{staticClass:"text-h5 white--text"},[_vm._v(_vm._s(_vm.post.name.substring(0, 1)))])]),_c(VListItemContent,[_c(VListItemTitle,{style:({'color' : _vm.post.font_color})},[_vm._v(_vm._s(_vm.post.name))]),_c(VListItemSubtitle,{style:({'color' : _vm.post.font_color})},[_vm._v(_vm._s(_vm.post.created_at))])],1),_c(VRow,{attrs:{"align":"center","justify":"end"}},[_c(VBtn,{attrs:{"dark":"","href":_vm.post.qr_code,"download":"qrcode.png","target":"_blank","fab":"","small":""}},[_c(VIcon,[_vm._v(" mdi-qrcode-scan ")])],1)],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"small":"","to":{name : 'Dashboard'},"text":"","block":"","color":_vm.post.font_color}},[_vm._v("Go to main dashboard")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
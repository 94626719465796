import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api_url : 'https://tesda.magnusjobfair.com/api',
    base_url : 'https://tesda.magnusjobfair.com',
    loading : false,
    user : JSON.parse(localStorage.getItem('user')) || null,
  },
  getters: {
  },
  mutations: {
    setLoading (state, value) {
      state.loading = value
    },
    setUser(state, user) {
      localStorage.setItem('user', JSON.stringify(user));
      state.user = user;
    }
  },
  actions: {
  },
  modules: {
  }
})

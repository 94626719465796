<template>
    <v-app>
      <v-navigation-drawer
            v-model="drawer"
            app
            src="@/assets/side-background.png"
        >   
            <div class="pt-5 pb-5 text-center logo-bg">
                <img class="mt-3" src="@/assets/logo.png" height="75" alt="">

                <div class="logo-text mt-3">
                    <h2>NTESDP</h2>
                    <div><strong class="red--text">2023-2028</strong></div>
                </div>
            </div>
            
            <v-divider></v-divider>
            <v-list dense nav>
                <v-list-item-group>
                    <v-list-item :to="{name : 'PendingTestimonials'}">
                        <v-list-item-icon>
                            <v-icon>mdi-message-text-clock</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>For Approval</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{name : 'ApprovedTestimonials'}">
                        <v-list-item-icon>
                            <v-icon>mdi-message-text</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Approved</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="viewDashboard()">
                        <v-list-item-icon>
                            <v-icon>mdi-view-dashboard-variant</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Live View</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logout()">
                        <v-list-item-icon>
                            <v-icon>mdi-power</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    
        <v-app-bar app color="primary" dark>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    
            <v-toolbar-title>NTESDP</v-toolbar-title>
        </v-app-bar>
  
        <router-view/>
    </v-app>
  </template>
<style scoped>
    .logo-bg {
        background: url('@/assets/logo-bg.png') repeat;
    }
</style>
<script>
export default {
    data: () => ({ drawer: null }),
    methods : {
    logout() {
        localStorage.removeItem('user');
        this.$router.push({name : 'Login'});
    },
    viewDashboard() {
        window.open('https://tesda.magnusjobfair.com/ui/#/dashboard', '_blank')
    }
    }
}
</script>
<template>
    <div class="pt-5 dark-bg">
        <v-card width="600" class="mx-auto">
            <v-img height="280" position="top" src="@/assets/banner-white.jpg"></v-img>
            <v-divider></v-divider>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field label="Email" color="primary" v-model="user.email" outlined dense hide-details></v-text-field>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-text-field label="Password" color="primary" v-model="user.password" type="password" outlined dense hide-details></v-text-field>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-card-actions>
                <v-btn block color="primary" 
                    @click="login()"
                    :disabled="$store.state.loading">
                    Login as Admin
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name : 'LoginView',
    data() {
        return {
            user : {}
        }
    },
    methods : {
        login() {
            this.$store.commit('setLoading', true);
            axios({
                url : this.$store.state.api_url + '/admin/login',
                method : 'POST',
                data : this.user
            }).then(res => {
                this.$store.commit('setLoading', false);
                this.$store.commit('setUser', res.data);
                this.$router.push({name : 'PendingTestimonials'});
            }).catch(err => {
                this.$store.commit('setLoading', false);
            })
        }
    }
}
</script>
<style scoped>
.dark-bg {
    background : #1a191f;
    width: 100%;
    height : 100%;
}
</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-5 dark-bg"},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"600"}},[_c(VImg,{attrs:{"height":"280","position":"top","src":require("@/assets/banner-white.jpg")}}),_c(VDivider),_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VTextField,{attrs:{"label":"Email","color":"primary","outlined":"","dense":"","hide-details":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VTextField,{attrs:{"label":"Password","color":"primary","type":"password","outlined":"","dense":"","hide-details":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","color":"primary","disabled":_vm.$store.state.loading},on:{"click":function($event){return _vm.login()}}},[_vm._v(" Login as Admin ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

<template>
    <div class="pa-5">
        <div class="mb-5">
            <h3>Approved Posts</h3>
        </div>
        <v-data-table
        :headers="headers"
        :items="testimonials"
        class="elevation-1"
        >
            <template v-slot:body = {items}>
                <tbody>
                    <tr
                        v-for="item in items"
                        :key="item.id"
                    >
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.company }}</td>
                        <td>{{ item.position }}</td>
                        <td>{{ item.created_at }}</td>
                        <td>
                            <v-btn x-small :color="item.visible == 1 ? 'primary' : 'error'">
                                {{ item.visible == 1 ? 'Yes' : 'No' }}
                            </v-btn>
                        </td>
                        <td>
                            <v-btn x-small  color="primary" @click="viewPostDetails(item)">
                                View Details
                            </v-btn>
                        </td>
                        <td>
                            <v-btn x-small icon @click="viewQRCode(item.qr_code)">
                                <v-icon>mdi-qrcode-scan</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-dialog v-model="post_dialog" width="500">
            <testimonial-details-dialog :post="post"></testimonial-details-dialog>
        </v-dialog>
        <v-dialog v-model="qr_code_dialog" width="350">
            <qr-code-dialog :qr_code="qr_code"></qr-code-dialog>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios';
    import TestimonialDetailsDialog from './dialog/TestimonialDetailsDialog.vue';
    import QrCodeDialog from './dialog/QRCodeDialog.vue';
    export default {
        name : 'ApprovedTestimonialsListView',
        components : {
            TestimonialDetailsDialog,
            QrCodeDialog
        },
        methods : {
            viewPostDetails(post) {
                this.post_dialog = true;
                this.post = post;
            },
            viewQRCode(qr_code) {
                this.qr_code_dialog = true;
                this.qr_code = qr_code;
            }
        },
        mounted() {
            axios({
                url : this.$store.state.api_url + '/admin/posts',
                method : 'GET',
                params : {approved : 1},
                headers : {
                    Authorization : 'Bearer ' + this.$store.state.user.token
                }
            }).then(res => {
                this.testimonials = res.data;
            });
        },
        data() {
            return {
                post : {},
                post_dialog : false,
                qr_code_dialog : false,
                qr_code : null, 
                search : '',
                headers : [
                    {
                        text : 'Name',
                        value : 'name',
                        sortable : true
                    },
                    {
                        text : 'Email',
                        value : 'email',
                        sortable : true
                    },
                    {
                        text : 'Company',
                        value : 'company',
                        sortable : true
                    },
                    {
                        text : 'Position',
                        value : 'position',
                        sortable : true
                    },
                    {
                        text : 'Submitted',
                        value : 'submitted',
                        sortable : true
                    },
                    {
                        text : 'Visible',
                        value : 'visible',
                        sortable : true
                    },
                    {
                        text : 'Details',
                    },
                    {
                        text : 'QR Code',
                        value : 'qr_code',
                    }
                ],
                testimonials : []
            }
        }
    }
</script>